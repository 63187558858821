import React, { Component } from "react";
import Fade from "react-reveal";
import backgroundImage from "./../assets/hero-image.png";

class Header extends Component {
  render() {
    if (!this.props.data) return null;

    const project = this.props.data.project;
    const github = this.props.data.github;
    const name = this.props.data.name;
    const description = this.props.data.description;

    const headerStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      height: "100vh",
      color: "white",
    };

    return (
      <header id="home" style={headerStyle}>
        <nav id="nav-wrap" style={{ fontSize: 20 }}>
          <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
            Show navigation
          </a>
          <a className="mobile-btn" href="#home" title="Hide navigation">
            Hide navigation
          </a>

          <ul id="nav" className="nav">
            <li className="current">
              <a
                className="smoothscroll"
                href="#home"
                style={{ color: "#009bcb" }}
              >
                Home
              </a>
            </li>

            <li>
              <a
                className="smoothscroll"
                href="#about"
                style={{ color: "#009bcb" }}
              >
                About Us
              </a>
            </li>

            <li>
              <a
                className="smoothscroll"
                href="#resume"
                style={{ color: "#009bcb" }}
              >
                Services
              </a>
            </li>

            <li>
              <a
                className="smoothscroll"
                href="#portfolio"
                style={{ color: "#009bcb" }}
              >
                Projects
              </a>
            </li>

            <li>
              <a
                className="smoothscroll"
                href="#partners"
                style={{ color: "#009bcb" }}
              >
                Partners
              </a>
            </li>

            <li>
              <a
                className="smoothscroll"
                href="#contact"
                style={{ color: "#009bcb" }}
              >
                Contact Us
              </a>
            </li>
          </ul>
        </nav>

        <div className="row banner">
          <div className="banner-text">
            <Fade bottom>
              {/* <h1 className="responsive-headline">{name}</h1> */}
            </Fade>
            <Fade bottom duration={1200}>
              <h3>{description}.</h3>
            </Fade>
            <hr />
          </div>
        </div>
        <p className="scrolldown">
          <a className="smoothscroll" href="#about">
            <i className="icon-down-circle"></i>
          </a>
        </p>
      </header>
    );
  }
}

export default Header;
