import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

class Contact extends Component {
  render() {
    if (!this.props.data) return null;

    const name = this.props.data.name;
    const street = this.props.data.address.street;
    const city = this.props.data.address.city;
    const state = this.props.data.address.state;
    const zip = this.props.data.address.zip;
    const phone = this.props.data.phone;
    const message = this.props.data.contactmessage;

    return (
      <section
        id="contact"
        style={{ backgroundColor: "#FFFFFF", color: "#009bcb" }}
      >
        <Fade bottom duration={1000}>
          <div className="row section-head">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>

            <div className="ten columns">
              <p className="lead">{message}</p>
            </div>
          </div>
        </Fade>

        <div className="row">
          <Slide left duration={1000}>
            <div className="eight columns">
              <form action="" method="post" id="contactForm" name="contactForm">
                <fieldset>
                  <div>
                    <label htmlFor="contactName" style={{ color: "#009bcb" }}>
                      Name <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactName"
                      name="contactName"
                      onChange={this.handleChange}
                      style={{ backgroundColor: "#e9ebee", color: "#000000" }}
                    />
                  </div>

                  <div>
                    <label htmlFor="contactEmail" style={{ color: "#009bcb" }}>
                      Email <span className="required">*</span>
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactEmail"
                      name="contactEmail"
                      onChange={this.handleChange}
                      style={{ backgroundColor: "#e9ebee", color: "#000000" }}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="contactSubject"
                      style={{ color: "#009bcb" }}
                    >
                      Subject
                    </label>
                    <input
                      type="text"
                      defaultValue=""
                      size="35"
                      id="contactSubject"
                      name="contactSubject"
                      onChange={this.handleChange}
                      style={{ backgroundColor: "#e9ebee", color: "#000000" }}
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="contactMessage"
                      style={{ color: "#009bcb" }}
                    >
                      Message <span className="required">*</span>
                    </label>
                    <textarea
                      cols="50"
                      rows="15"
                      id="contactMessage"
                      name="contactMessage"
                      style={{ backgroundColor: "#e9ebee", color: "#000000" }}
                    ></textarea>
                  </div>

                  <div>
                    <button
                      className="submit"
                      style={{ backgroundColor: "#e9ebee", color: "#009bcb" }}
                    >
                      Submit
                    </button>
                    <span id="image-loader">
                      <img alt="" src="images/loader.gif" />
                    </span>
                  </div>
                </fieldset>
              </form>

              <div id="message-warning"> Error boy</div>
              <div id="message-success">
                <i className="fa fa-check"></i>Your message was sent, thank you!
                <br />
              </div>
            </div>
          </Slide>

          <Slide right duration={1000}>
            <aside className="four columns footer-widgets">
              <div className="widget widget_contact">
                <h4>Address and Phone</h4>
                <p className="address">
                  {name}
                  <br />
                  {street} <br />
                  {city}, {state} {zip}
                  <br />
                  <span>{phone}</span>
                </p>
              </div>

              <div className="widget widget_tweets">
                <h4 className="widget-title">Latest Tweets</h4>
                <ul id="twitter">
                  <li>
                    <span style={{ color: "#009bcb" }}>
                      This is Photoshop's version of Lorem Ipsum. Proin gravida
                      nibh vel velit auctor aliquet. Aenean sollicitudin, lorem
                      quis bibendum auctor, nisi elit consequat ipsum
                      <a href="./" style={{ color: "#009bcb" }}>
                        https://ablaksolutions.com
                      </a>
                    </span>
                    <b>
                      <a href="./" style={{ color: "#009bcb" }}>
                        2 Days Ago
                      </a>
                    </b>
                  </li>
                  <li>
                    <span style={{ color: "#009bcb" }}>
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa quae ab illo inventore veritatis et
                      quasi
                      <a href="./" style={{ color: "#009bcb" }}>
                        https://ablaksolutions.com
                      </a>
                    </span>
                    <b>
                      <a href="./" style={{ color: "#009bcb" }}>
                        3 Days Ago
                      </a>
                    </b>
                  </li>
                </ul>
              </div>
            </aside>
          </Slide>
        </div>
      </section>
    );
  }
}

export default Contact;
